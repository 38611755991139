import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  Checkbox,
  Paper,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  ButtonBase,
} from '@material-ui/core';
import _ from 'lodash';
import { PageHeaderUser } from '../../component';
import {
  ROUTE_NAME_DASHBOARD_USER,
  DRAWER_NOTIFICATION_WIDTH,
  DEFAULT_STYLES,
  CART_SERVICE_NAME_TRUCKING,
  CART_SERVICE_NAME_IMPORT,
} from '../../constant';
import LocalizedString from '../../localization';
import { toCurrency } from '../../helper';
import CartTabs from './tabs';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnCheckout: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  cardAction: {
    padding: '16px',
    borderTop: '1px solid #eeeeee',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '-24px',
    zIndex: 1,
  },
  paper: {
    marginTop: '24px',
  },
  cardPayment: {
    marginTop: '-24px',
  },
  cartItemHeader: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '8px',
    flexGrow: 1,
    paddingRight: '8px !important',
  },
  cartItemDetail: {
    display: 'flex',
    alignItems: 'baseline',
    borderTop: '1px solid #EEEEEE',
    padding: '16px 8px',
    flexGrow: 1,
  },
  expandIcon: {
    transition: 'transform 0.2s',
  },
  expandIconActivated: {
    transform: 'rotate(-180deg)',
  },
  lnkPaymentExpected: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  leftColumn: {
    padding: '0 12px',
    width: '96px',
  },
  rightColumn: {
    padding: '0 12px',
    borderLeft: '1px solid silver',
    flexGrow: 1,
  },
  rightColumnDetail: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noBorder: {
    border: 'none',
  },
  expansionPanelSummary: {
    padding: 0,
    margin: '0px !important',
  },
  expansionPanelSummaryExpanded: {
    margin: '0px !important',
  },
});

const isAllItemChecked = (shoppingCart, selectedItems) => {
  const items = _.flatMap(shoppingCart);
  return items.length === items.filter(item => selectedItems.includes(item.itemId)).length;
};

const isAllDetailChecked = (cartItem, selectedItems) => cartItem
  .filter(item => selectedItems.includes(item.itemId)).length === cartItem.length;

const calculateTotalPayment = (shoppingCart, selectedItems) => _.flatMap(shoppingCart)
  .filter(item => selectedItems.includes(item.items ? item.items[0].itemId : item.itemId))
  .reduce((accumulated, current) => (accumulated + (current.items
    ? current.items[0].amount : current.amount)), 0);

const showTruckingButton = items => items.every(item => item.service !== CART_SERVICE_NAME_TRUCKING)
  && items.some(item => item.service === CART_SERVICE_NAME_IMPORT);

const CartPage = ({
  classes,
  notificationOpen,
  shoppingCart,
  selectedItems,
  expandedBlNumber,
  toDeleteItems,
  userCompany,
  selectedTab,
  hasBothPaymentMethods,

  onAppear,
  onViewDetailPressed,
  onSelectAllCheckboxPressed,
  onMainCheckboxPressed,
  onDetailCheckboxPressed,
  onExpansionPanelPressed,
  onSelectAllDeletePressed,
  onMainDeletePressed,
  onDetailDeletePressed,
  onCancelDeletePressed,
  onConfirmDeletePressed,
  onCheckoutPressed,
  onOrderTruckingPressed,
  onTabPressed,
}) => {
  useEffect(() => onAppear(), []);

  const renderCartItemDetail = items => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {items && items.map(item => (
        <div className={classes.cartItemDetail}>
          <div>
            {/* <Checkbox
              checked={selectedItems.includes(item.itemId)}
              color="primary"
              onClick={() => onDetailCheckboxPressed(item.itemId, selectedItems)}
            /> */}
          </div>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <div className={classes.leftColumn}>
              <ButtonBase
                onClick={() => onViewDetailPressed(item, userCompany, selectedTab)}
              >
                <Typography>
                  {item.service}
                </Typography>
              </ButtonBase>
            </div>
            <div
              className={
                classNames(classes.rightColumn,
                  classes.rightColumnDetail,
                  classes.noBorder)
              }
            >
              <div>
                <ButtonBase
                  className={classes.lnkPaymentExpected}
                  onClick={() => onViewDetailPressed(item, userCompany)}
                >
                  <Typography variant="caption">
                    {LocalizedString.cart.labelPaymentExpect}
                  </Typography>
                  <Typography>
                    Rp.&nbsp;
                    {toCurrency(item.items ? item.items[0].amount : item.amount)}
                  </Typography>
                </ButtonBase>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Typography variant="caption">
                  {LocalizedString.cart.labelStatus}
                </Typography>
                <Typography>Confirmed</Typography>
              </div>
            </div>
          </div>
          <div>
            {/* <IconButton
              onClick={() => onDetailDeletePressed(item)}
            >
              <Icon color="secondary">delete</Icon>
            </IconButton> */}
          </div>
        </div>
      ))}
      {/* {showTruckingButton(items) && (
        <div className={classes.cardAction}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => onOrderTruckingPressed(items[0].blNumber)}
          >
            Order Trucking
          </Button>
        </div>
      )} */}
    </div>
  );

  const renderCartItemExpansible = (blNumber, cartItem) => (
    <ExpansionPanel
      className={classes.paper}
      expanded={expandedBlNumber.includes(blNumber)}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummary,
          content: classes.expansionPanelSummaryExpanded,
        }}
        onClick={() => onExpansionPanelPressed(blNumber, expandedBlNumber)}
      >
        <div className={classes.cartItemHeader}>
          <div>
            <Checkbox
              checked={isAllDetailChecked(cartItem, selectedItems)}
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onMainCheckboxPressed(isAllDetailChecked(cartItem, selectedItems),
                  cartItem, selectedItems);
              }}
            />
          </div>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <div className={classes.leftColumn}>
              <Typography>{LocalizedString.cart.labelBlNumber}</Typography>
            </div>
            <div className={classes.rightColumn}>
              <Typography color="primary">
                <strong>{blNumber}</strong>
              </Typography>
              <br />
              <Typography variant="caption">
                {`${cartItem.length} item(s)`}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onMainDeletePressed(cartItem);
              }}
            >
              <Icon color="secondary">delete</Icon>
            </IconButton>
            <IconButton className={
              classNames(classes.expandIcon,
                {
                  [classes.expandIconActivated]: expandedBlNumber.includes(blNumber),
                })
              }
            >
              <Icon>expand_more</Icon>
            </IconButton>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelSummary}>
        {renderCartItemDetail(cartItem)}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  const renderTotalPayment = () => (
    <Card className={classes.cardPayment}>
      <CardContent>
        <Typography variant="body2" className={classes.upperCase}>
          {LocalizedString.cart.labelTotalPayment}
        </Typography>
        <Typography variant="h6" color="primary">
          Rp.&nbsp;
          {toCurrency(calculateTotalPayment(shoppingCart, selectedItems))}
        </Typography>
      </CardContent>
      <CardActions style={{ borderTop: '1px solid #EEEEEE' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className={classes.btnCheckout}
          onClick={() => onCheckoutPressed(shoppingCart, selectedItems)}
          disabled={!selectedItems.length}
        >
          {LocalizedString.cart.btnCheckout}
        </Button>
      </CardActions>
    </Card>
  );

  const onTabPress = (tabName) => {
    onTabPressed(tabName);
    onSelectAllCheckboxPressed(isAllItemChecked(shoppingCart, selectedItems), shoppingCart,
      selectedItems);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} style={{ zIndex: 3 }}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.cart.title}</Typography>
                      <Typography variant="caption">{LocalizedString.cart.caption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            {hasBothPaymentMethods && (
            <Grid item xs={12} md={8}>
              <CartTabs
                selectedTab={selectedTab}
                onTabPressed={onTabPress}
              />
            </Grid>
            )}

            <Grid item xs={12} md={8}>
              <Paper className={classes.selectAllContainer}>
                <div style={{ padding: '8px' }}>
                  <Checkbox
                    checked={isAllItemChecked(shoppingCart, selectedItems)}
                    color="primary"
                    onClick={() => onSelectAllCheckboxPressed(isAllItemChecked(shoppingCart,
                      selectedItems), shoppingCart, selectedItems)}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typography>{LocalizedString.cart.labelSelectAll}</Typography>
                </div>
                <div style={{ padding: '8px' }}>
                  <IconButton
                    onClick={() => onSelectAllDeletePressed(shoppingCart)}
                  >
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              </Paper>

              {Object.keys(shoppingCart)
                .map(key => renderCartItemExpansible(key, shoppingCart[key]))}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderTotalPayment()}
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={Boolean(toDeleteItems.length)}
      >
        <DialogTitle>{LocalizedString.common.alertTitleConfirmation}</DialogTitle>
        <DialogContent>
          {LocalizedString.common.msgDeleteConfirmation}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => onConfirmDeletePressed(toDeleteItems)}
          >
            {LocalizedString.common.buttonCaptionYes}
          </Button>
          &emsp;&emsp;
          <Button
            variant="contained"
            color="primary"
            onClick={onCancelDeletePressed}
          >
            {LocalizedString.common.buttonCaptionCancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CartPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  shoppingCart: PropTypes.shape(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedBlNumber: PropTypes.arrayOf(PropTypes.string).isRequired,
  toDeleteItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  selectedTab: PropTypes.string.isRequired,
  hasBothPaymentMethods: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onViewDetailPressed: PropTypes.func.isRequired,
  onSelectAllCheckboxPressed: PropTypes.func.isRequired,
  onMainCheckboxPressed: PropTypes.func.isRequired,
  onDetailCheckboxPressed: PropTypes.func.isRequired,
  onExpansionPanelPressed: PropTypes.func.isRequired,
  onSelectAllDeletePressed: PropTypes.func.isRequired,
  onMainDeletePressed: PropTypes.func.isRequired,
  onDetailDeletePressed: PropTypes.func.isRequired,
  onCancelDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCheckoutPressed: PropTypes.func.isRequired,
  onOrderTruckingPressed: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartPage);
