import uuid from 'uuid/v4';
import moment from 'moment';
import { setCancellingOutstandingOrderStatus, addShoppingCartItem, setShoppingCartSelectedTab } from '../simple-action';
import { cancelOrder } from '../../../helper';
import {
  CART_SERVICE_NAME_EXPORT, CART_SERVICE_NAME_IMPORT, ROUTE_NAME_CART_PAGE, CART_TAB_CIP,
} from '../../../constant';

const getItem = (item, getState) => {
  if (item.service === CART_SERVICE_NAME_EXPORT) {
    const { exportOrderHistory } = getState();
    const findExportItem = Object.values(exportOrderHistory)
      .find(exportItem => exportItem.blNo === item.blNumber);
    return findExportItem;
  } if (item.service === CART_SERVICE_NAME_IMPORT) {
    const { tariffSimulationOrder, importReleaseOrder } = getState();

    const findImportItem = Object.values(tariffSimulationOrder)
      .find(importItem => importItem.blNo === item.blNumber)
      || Object.values(importReleaseOrder).find(importItem => importItem.blNo === item.blNumber);

    // const billToPaymentMethod = tariffSimulationOrder[item.blNumber]
    //   ? tariffSimulationOrder[item.blNumber].billToPaymentMethod : '';

    // const allowPpjkToUseDeposit = tariffSimulationOrder[item.blNumber]
    //   ? tariffSimulationOrder[item.blNumber].allowPpjkToUseDeposit : '';

    const billToPaymentMethod = findImportItem
      ? findImportItem.billToPaymentMethod : '';

    const allowPpjkToUseDeposit = findImportItem
      ? findImportItem.allowPpjkToUseDeposit : '';

    return { ...findImportItem, billToPaymentMethod, allowPpjkToUseDeposit };
  }
  const { feederServiceOrderHistories } = getState();
  return feederServiceOrderHistories;
};

export default (proformaNo, router) => async (dispatch, getState) => {
  try {
    dispatch(setCancellingOutstandingOrderStatus(true));
    const { token } = getState().authentication;
    const body = { proformaNo };
    const cancelledItems = await cancelOrder(token, body);

    /** API result structure
      {
        amount: 28832936
        billTo: "JOYSON"
        blNumber: "KMTCLCH2682720"
        id: null
        info: "2020-09-10"
        invoiceNo: "020000024"
        paymentOnBehalfOf: "JOYSON"
        service: "Import"
      }
     */

    const returnedItems = Object.values(cancelledItems).map(item => ({
      itemId: item.id || uuid(),
      blNumber: item.blNumber,
      service: item.service,
      amount: item.amount,
      info: item.info,
      paymentOnBehalfOf: item.paymentOnBehalfOf,
      addedToCartOn: moment().format(),
      checkout: false,
      invoiceNo: item.invoiceNo,
      billTo: item.billTo,
      item: getItem(item, getState),
    }));
    returnedItems.forEach((item) => {
      dispatch(addShoppingCartItem(item));
    });

    dispatch(setShoppingCartSelectedTab(CART_TAB_CIP));
    router.push(ROUTE_NAME_CART_PAGE);
  } finally {
    dispatch(setCancellingOutstandingOrderStatus(false));
  }
};
