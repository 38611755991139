import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import CartPage from './cart.presentation';
import {
  setCartItemChecked,
  removeCartItemChecked,
  emptyCartItemChecked,
  setExpandedCartItem,
  removeExpandedCartItem,
  addToDeleteItem,
  emptyToDeleteItem,
  addToShoppingCheckoutAsync,
  removeShoppingCartItemAsync,
  showErrorSnackbar,
  bookFeederFromOtherAsync,
  setTappedContainerTrackingBlNo,
  setTariffSimulationSearchText,
  setMyPaymentSelectedTab,
  setMyPaymentTermSearchText,
  setTappedTermBlNo,
  setExportSearchText,
  setTappedExportBlNo,
  setFeederServiceSearchText,
  setTappedFeederServiceBlNo,
  setMyPaymentOutstandingSearchText,
  setTappedOutstandingItemNo,
  setCombineTariffSimulationOrderListWithImportReleaseOrder,
  setShoppingCartSelectedTab,
} from '../../redux/action';
import {
  ROUTE_NAME_CHECKOUT_PAGE,
  ROUTE_NAME_TRUCKING,
  CART_SERVICE_NAME_IMPORT,
  PAYMENT_TYPE_TERM,
  ROUTE_NAME_MY_PAYMENT,
  ROUTE_NAME_TARIFF_SIMULATION,
  CART_SERVICE_NAME_EXPORT,
  CART_SERVICE_NAME_TRUCKING,
  CART_TAB_CIP,
  ROUTE_NAME_EXPORT,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  isCgsItem, unauthorizedErrorHandler, getPaymentMethod, isShowCartTabs,
} from '../../helper';

const getShoppingCartItems = (state) => {
  const { currentUser, shoppingCart, uiCart } = state;
  const { selectedTab } = uiCart;

  const hasBothPaymentMethods = isShowCartTabs(currentUser.company);

  if (hasBothPaymentMethods) {
    const initData = Object.values(shoppingCart);
    const cipTabData = initData.filter(x => !isCgsItem(x));
    const cgsTabData = initData.filter(x => isCgsItem(x));
    const result = selectedTab === CART_TAB_CIP ? cipTabData : cgsTabData;
    return _.groupBy(result, 'blNumber');
  }
  return _.groupBy(Object.values(shoppingCart), 'blNumber');
};

const mapStateToProps = state => ({
  shoppingCart: getShoppingCartItems(state),
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  selectedItems: state.uiCart.selectedItem,
  expandedBlNumber: state.uiCart.expandedBlNumber,
  toDeleteItems: state.uiCart.toDeleteItems,
  blItems: Object.values(state.feederServiceBLList),
  userCompany: state.currentUser.company,
  selectedTab: state.uiCart.selectedTab,
  hasBothPaymentMethods: isShowCartTabs(state.currentUser.company),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    dispatch(setShoppingCartSelectedTab(CART_TAB_CIP));
  },
  onSelectAllCheckboxPressed: (previousValue, shoppingCart, selectedItems) => {
    if (previousValue) {
      dispatch(emptyCartItemChecked());
    } else {
      _.flatMap(shoppingCart)
        .filter(item => !selectedItems.includes(item.itemId))
        .forEach((item) => {
          dispatch(setCartItemChecked(item.itemId));
        });
    }
  },
  onMainCheckboxPressed: (previousValue, cartItem, selectedItems) => {
    if (previousValue) {
      // all items were selected
      cartItem.forEach((item) => {
        dispatch(removeCartItemChecked(item.itemId));
      });
    } else {
      cartItem.filter(item => !selectedItems.includes(item.itemId))
        .forEach((item) => {
          dispatch(setCartItemChecked(item.itemId));
        });
    }
  },
  onDetailCheckboxPressed: (itemId, selectedItems) => {
    if (selectedItems.includes(itemId)) {
      dispatch(removeCartItemChecked(itemId));
    } else {
      dispatch(setCartItemChecked(itemId));
    }
  },
  onExpansionPanelPressed: (blNumber, expandedBlNumber) => {
    if (expandedBlNumber.includes(blNumber)) {
      dispatch(removeExpandedCartItem(blNumber));
    } else {
      dispatch(setExpandedCartItem(blNumber));
    }
  },
  onViewDetailPressed: (item, userCompany, selectedTab) => {
    const paymentMethod = getPaymentMethod(selectedTab, userCompany);
    if (paymentMethod === PAYMENT_TYPE_TERM) {
      const nowStr = moment().format('YYYY-MM-DDT00:00:00');
      const now = moment(nowStr);
      const timeDiff = moment(item.item.termPaymentDueDate).diff(now, 'days');

      if (timeDiff < 0) {
        dispatch(setMyPaymentOutstandingSearchText(item.item.invoiceNo));
        dispatch(setTappedOutstandingItemNo(item.item.invoiceNo));
        dispatch(setMyPaymentSelectedTab(0));
      } else {
        dispatch(setMyPaymentTermSearchText(item.item.invoiceNo));
        dispatch(setTappedTermBlNo(item.item.invoiceNo));
        dispatch(setMyPaymentSelectedTab(1));
      }
      ownProps.history.push(ROUTE_NAME_MY_PAYMENT);
    } else if (item.service === CART_SERVICE_NAME_IMPORT) {
      dispatch(setTariffSimulationSearchText(item.blNumber));
      dispatch(setTappedContainerTrackingBlNo(item.blNumber));
      dispatch(setCombineTariffSimulationOrderListWithImportReleaseOrder(true));
      ownProps.history.push(ROUTE_NAME_TARIFF_SIMULATION);
    } else if (item.service === CART_SERVICE_NAME_EXPORT) {
      dispatch(setExportSearchText(item.blNumber));
      dispatch(setTappedExportBlNo(item.item.coRef));
      ownProps.history.push(ROUTE_NAME_EXPORT);
    } else if (item.service === CART_SERVICE_NAME_TRUCKING) {
      dispatch(setFeederServiceSearchText(item.blNumber));
      dispatch(setTappedFeederServiceBlNo(item.item.refNo));
      ownProps.history.push(ROUTE_NAME_TRUCKING);
    }
  },
  onDetailDeletePressed: (item) => {
    dispatch(addToDeleteItem(item));
  },
  onMainDeletePressed: (cartItem) => {
    cartItem.forEach(item => dispatch(addToDeleteItem(item)));
  },
  onSelectAllDeletePressed: (shoppingCart) => {
    _.flatMap(shoppingCart).forEach(item => dispatch(addToDeleteItem(item)));
  },
  onCancelDeletePressed: () => {
    dispatch(emptyToDeleteItem());
  },
  onConfirmDeletePressed: async (toDeleteItems) => {
    try {
      await dispatch(removeShoppingCartItemAsync(toDeleteItems));
      dispatch(emptyToDeleteItem());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onCheckoutPressed: async (shoppingCart, selectedItems) => {
    // add all selected item to checkout
    try {
      await dispatch(addToShoppingCheckoutAsync(shoppingCart, selectedItems));
      ownProps.history.push(ROUTE_NAME_CHECKOUT_PAGE);
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onOrderTruckingPressed: async (blNo) => {
    try {
      await dispatch(bookFeederFromOtherAsync(blNo, ownProps.history));
    } catch (error) {
      dispatch(
        showErrorSnackbar(LocalizedString.common.alertTitleError, error.message),
      );
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onTabPressed: (tabName) => {
    dispatch(setShoppingCartSelectedTab(tabName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
