import _ from 'lodash';
import * as Action from '../action';
import { CART_TAB_CIP } from '../../constant';

const initialState = {
  selectedItem: [],
  expandedBlNumber: [],
  toDeleteItems: [],
  selectedTab: CART_TAB_CIP,
};

const filterSelectedItem = (selectedItem, deleteId) => selectedItem
  .filter(item => item !== deleteId);

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_CART_ITEM_CHECKED: {
      return { ...state, selectedItem: [...state.selectedItem, action.itemId] };
    }
    case Action.REMOVE_CART_ITEM_CHECKED: {
      return {
        ...state,
        selectedItem: filterSelectedItem(state.selectedItem, action.itemId),
      };
    }
    case Action.EMPTY_CART_ITEM_CHECKED: {
      return { ...state, selectedItem: [] };
    }
    case Action.SET_EXPANDED_CART_ITEM: {
      return { ...state, expandedBlNumber: [...state.expandedBlNumber, action.blNumber] };
    }
    case Action.REMOVE_EXPANDED_CART_ITEM: {
      return { ...state, expandedBlNumber: _.without(state.expandedBlNumber, action.blNumber) };
    }
    case Action.ADD_TO_DELETE_ITEM: {
      return { ...state, toDeleteItems: [...state.toDeleteItems, action.item] };
    }
    case Action.EMPTY_TO_DELETE_ITEM: {
      return { ...state, toDeleteItems: [] };
    }
    case Action.SET_SHOPPING_CART_SELECTED_TAB:
      return { ...state, selectedTab: action.text };
    default: {
      return state;
    }
  }
};
