import { connect } from 'react-redux';
import _ from 'lodash';
import CartPage from './checkout.presentation';
import {
  clearUIError,
  setDialogPaymentConfirmStatus,
  setDialogPaymentCompleteStatus,
  setUploadingPayment,
  removeExpandedCheckoutItem,
  setExpandedCheckoutItem,
  setCheckoutPasswordTextInput,
  payCheckoutItemAsync,
  setDialogPaymentDokuStatus,
  startPaymentProcess,
  setDialogConfirmClosePaymentDokuStatus,
  setTopUpDialogStatus,
  showErrorSnackbar,
  setCheckoutBankSelection,
  setDialogSelectBankStatus,
  downloadMoneyLoanInfoAsync,
  setMoneyLoanTncDialog,
  setCheckoutTermsAndConditionChecked,
  setDialogCheckoutTermAndCondition,
} from '../../redux/action';
import {
  RXSTATE_CHECKOUT_PAGE,
  ROUTE_NAME_MY_PAYMENT,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getShoppingCartItems = state => _.groupBy(state.shoppingCheckout, 'blNumber');

const mapStateToProps = state => ({
  error: state.uiCheckout.error,
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  userCompany: state.currentUser.company,
  password: state.uiCheckout.password,
  shoppingCheckout: getShoppingCartItems(state),
  expandedBlNumber: state.uiCheckout.expandedBlNumber,
  uploadingPayment: state.uiCheckout.uploadingPayment,
  paymentResult: state.uiPaymentResult.result,
  selectedBank: state.uiCheckout.selectedBank,
  dialogPaymentConfirmStatus: state.uiCheckout.dialogPaymentConfirmStatus,
  dialogPaymentCompleteStatus: state.uiCheckout.dialogPaymentCompleteStatus,
  dialogPaymentSelectBankStatus: state.uiCheckout.dialogPaymentSelectBankStatus,
  dialogConfirmClosePaymentStatus: state.uiPayment.dialogConfirmClosePaymentStatus,
  dialogTopUpStatus: state.uiDepositHistory.dialogTopUpStatus,
  dialogTermAndCondition: state.uiCheckout.dialogTermAndCondition,
  moneyLoanInfo: state.moneyLoanInfo,
  termAndConditionChecked: state.uiCheckout.termAndConditionChecked,
  termAndConditionText: state.uiCheckout.termAndConditionText,
  selectedTab: state.uiCart.selectedTab,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    dispatch(downloadMoneyLoanInfoAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
    dispatch(setDialogCheckoutTermAndCondition(false));
  },
  onMoneyLoanPressed: () => {
    dispatch(setMoneyLoanTncDialog(true));
  },
  onChangePasswordText: (value) => {
    dispatch(setCheckoutPasswordTextInput(value));
  },
  onExpansionPanelPressed: (blNumber, expandedBlNumber) => {
    if (expandedBlNumber.includes(blNumber)) {
      dispatch(removeExpandedCheckoutItem(blNumber));
    } else {
      dispatch(setExpandedCheckoutItem(blNumber));
    }
  },
  onPayPressed: (staticVa) => {
    if (staticVa) {
      dispatch(setDialogSelectBankStatus(true));
    } else {
      dispatch(setDialogPaymentConfirmStatus(true));
    }
  },
  onCancelPressed: () => {
    dispatch(setCheckoutPasswordTextInput(''));
    dispatch(setDialogPaymentConfirmStatus(false));
    dispatch(clearUIError(RXSTATE_CHECKOUT_PAGE));
  },
  onSubmitPressed: async (password, useCustomerDeposit) => {
    try {
      dispatch(clearUIError(RXSTATE_CHECKOUT_PAGE));
      dispatch(setUploadingPayment(true));
      await dispatch(payCheckoutItemAsync(password, useCustomerDeposit, ownProps.history.push));
      dispatch(setDialogPaymentConfirmStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(setUploadingPayment(false));
    }
  },
  onGoToPaymentHistoryPressed: () => {
    dispatch(setDialogPaymentCompleteStatus(false));
    ownProps.history.push(ROUTE_NAME_MY_PAYMENT);
  },
  onOkClosePaymentPressed: () => {
    dispatch(setDialogPaymentDokuStatus(false));
    dispatch(startPaymentProcess(false));
    dispatch(setDialogConfirmClosePaymentDokuStatus(false));
  },
  onCancelClosePaymentPressed: () => {
    dispatch(setDialogConfirmClosePaymentDokuStatus(false));
  },
  onTopUpPressed: () => {
    dispatch(setTopUpDialogStatus(true));
  },
  onTopUpClosePressed: () => {
    dispatch(setTopUpDialogStatus(false));
  },

  onBanOptionkSelected: (value) => {
    dispatch(setCheckoutBankSelection(value));
  },
  onOkBankOptionPressed: () => {
    dispatch(setDialogSelectBankStatus(false));
    dispatch(setDialogPaymentConfirmStatus(true));
  },
  onCancelBankOptionPressed: () => {
    dispatch(setDialogSelectBankStatus(false));
  },
  onCheckboxPressed: (status) => {
    dispatch(setCheckoutTermsAndConditionChecked(!status));
  },
  onTermAndConditionPressed: () => {
    dispatch(setDialogCheckoutTermAndCondition(true));
  },
  onTermAndConditionDialogClosePressed: () => {
    dispatch(setDialogCheckoutTermAndCondition(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
