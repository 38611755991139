import { connect } from 'react-redux';
import { reset } from 'redux-form';
import _ from 'lodash';
import { withRouter } from 'react-router';
import TariffSimulationBlCard from './bl-card.presentation';
import {
  showErrorSnackbar,
  setMyPaymentSelectedTab,
  setTappedTermBlNo,
  setTappedContainerTrackingBlNo,
  setChosenTariffSimulationDate,
  addTariffSimulation,
  setSelectedTariffSimulationPaymentBehalfOf,
  setDialogCalendarStatus,
  setMyPaymentTermSearchText,
  setTariffSimulationAction, setShoppingCartSelectedTab,
} from '../../redux/action';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, toMoment } from '../../helper';

const getTariffSimulationOrders = (state) => {
  const allImportOrders = state.uiTariffSimulation.combineWithImportRelease
    ? _.uniqBy(_.union(_.values(state.tariffSimulationOrder), _.values(state.importReleaseOrder)), x => x.blNo)
    : _.values(state.tariffSimulationOrder);
  return allImportOrders
    .filter(x => x.blNo.toUpperCase()
      .indexOf(state.uiTariffSimulation.searchBarText.toUpperCase()) >= 0)
    .sort((left, right) => toMoment(right.gateInCdp) - toMoment(left.gateInCdp));
};

const mapStateToProps = state => ({
  userCompany: state.currentUser.company,
  bl: getTariffSimulationOrders(state),
  selectedBlNo: state.uiTariffSimulation.tappedBlNo,
  shoppingCart: _.values(state.shoppingCart),
  termOrder: _.values(state.myTermOrder),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMorePressed: async (tappedBlNo, foundOnCart, shoppingCart, foundOnTerm, termItem) => {
    try {
      if (foundOnTerm) {
        dispatch(setMyPaymentSelectedTab(1));
        dispatch(setTappedTermBlNo(termItem.invoiceNo));
        ownProps.history.push(Constant.ROUTE_NAME_MY_PAYMENT);
      } else if (foundOnCart) {
        const { item: tariffSimulation } = shoppingCart
          .find(shopItem => shopItem.blNumber === tappedBlNo);
        await dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
        dispatch(setChosenTariffSimulationDate(tariffSimulation.sp2Expectation));
        dispatch(addTariffSimulation(tariffSimulation));
      } else {
        dispatch(setChosenTariffSimulationDate(''));
        dispatch(setSelectedTariffSimulationPaymentBehalfOf(''));
        dispatch(addTariffSimulation({}));
        await dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
        dispatch(setDialogCalendarStatus(true));
      }
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onNextPressed: (tappedBlNo) => {
    dispatch(reset(Constant.RXFORM_TARIFF_SIMULATION_DIALOG));
    dispatch(setTariffSimulationAction(Constant.TARIFF_SIMULATION_ACTION_NEXT));
    dispatch(setChosenTariffSimulationDate(''));
    dispatch(setSelectedTariffSimulationPaymentBehalfOf(''));
    dispatch(addTariffSimulation({}));
    dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
    dispatch(setDialogCalendarStatus(true));
  },
  onExtendPressed: (tappedBlNo, validSp2Date, validDoDate) => {
    dispatch(setTariffSimulationAction(Constant.TARIFF_SIMULATION_ACTION_EXTEND));
    dispatch(setChosenTariffSimulationDate(''));
    dispatch(setSelectedTariffSimulationPaymentBehalfOf(''));
    dispatch(addTariffSimulation({}));
    dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
    dispatch(setDialogCalendarStatus(true));
  },
  onOpenTermPressed: (tappedBlNo, termItem) => {
    dispatch(setMyPaymentSelectedTab(1));
    dispatch(setMyPaymentTermSearchText(tappedBlNo));
    dispatch(setTappedTermBlNo(termItem.invoiceNo));
    ownProps.history.push(Constant.ROUTE_NAME_MY_PAYMENT);
  },
  onOpenCartPressed: () => {
    dispatch(setShoppingCartSelectedTab(Constant.CART_TAB_CIP));
    ownProps.history.push(Constant.ROUTE_NAME_CART_PAGE);
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TariffSimulationBlCard));
