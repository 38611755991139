import { PRINT_TYPE_INVOICE } from '../../../constant';
import {
  clearInvoiceInfo, downloadingInvoiceInfo, setInvoiceInfo, setMyPaymentHistoryInfoDialogVisibility,
  setPdfViewerStatus,
} from '../simple-action';
import * as Helper from '../../../helper';
import requestPrintAsync from './requestPrintAsync';

export default item => async (dispatch, getState) => {
  const { token } = getState().authentication;

  dispatch(clearInvoiceInfo());

  try {
    dispatch(downloadingInvoiceInfo(true));
    const invoiceInfo = await Helper.downloadInvoiceInfo(item.invoiceNo, token);
    dispatch(setInvoiceInfo(invoiceInfo));

    if (Helper.isPrintCgsItem(item, invoiceInfo)) {
      dispatch(setMyPaymentHistoryInfoDialogVisibility(true, item));
    } else {
      const data = {
        printType: PRINT_TYPE_INVOICE,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    }
  } finally {
    dispatch(downloadingInvoiceInfo(false));
  }
};
